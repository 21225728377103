import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div class="notfound">
        <div class="container">
            <h1 class="title is-1"><strong>404</strong></h1>
            <h2 class="title is-3">Oups! La page n'a pu &ecirc;tre trouv&eacute;e.</h2>
            <p>D&eacute;sol&eacute;, la page que vous recherchez n'existe pas, ou a &eacute;t&eacute; retir&eacute;e.</p>
            <br/>
            <Link to="/" className="button is-primary">
              Retour &agrave; l'accueil
            </Link>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage
